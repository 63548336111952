/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
//@ts-ignore
import { Box, OptionsList, NavSidebar } from "@enerbit/base";
import DiagnosticCompliancePage from "../diagnosticCompliance/DiagnosticCompliancePage";
import { Reports } from "../records/Records";
import { PendingDiagnostic } from "../PendingDiagnostics/PendingDiagnostic";
import DownloadDiagnostic from "../downloadDiagnostic/DownloadDiagnostic";
import { DeviceVerification } from "../deviceVerification";

const TELEMETRY_POSITION = 8;

const RecordsContainer = () => {
  const [positionOption, setPositionOption] = useState(
    parseInt(localStorage.getItem("recordsPosition")!) || 2
  );

  const dataBoton = [
    {
      name: "Gestión campo",
      position: 0,
      component: null as any,
      isTitle: true,
    },
    {
      name: "Reportes",
      position: 1,
      isSubtitle: true,
    },
    {
      name: "Reportes",
      position: 2,
      component: <Reports />,
      isTitle: false,
    },
    { name: "Herramientas de verificación", position: 5, isSubtitle: true },
    {
      name: "Telemetría y prealistamiento",
      position: 3,
      isTitle: false,
      component: <DeviceVerification />,
    },
  ];

  useEffect(() => {
    localStorage.setItem("recordsPosition", `${positionOption}`);
  }, [positionOption]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <NavSidebar />
      <Box
        sx={{
          display: "flex",
          position: "relative",
          flexGrow: 1,
          paddingTop: "70px",
          overflow: "auto",
        }}
      >
        <OptionsList
          sx={{ fontSize: "28px" }}
          arrayOptions={dataBoton}
          positionOption={positionOption}
          setPositionOption={setPositionOption}
        />
        <div
          style={{
            zIndex: "0",
            width: "100%",
            overflowY: "scroll",
            backgroundColor:
              positionOption === TELEMETRY_POSITION ? "#F2F4F7" : undefined,
          }}
        >
          {dataBoton[positionOption].component}
        </div>
      </Box>
    </div>
  );
};

export default RecordsContainer;
